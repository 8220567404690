const api = {}

const BASE_URL = "https://mini-bo-api-23hjk3yt7823yhk.cleanbox.co.il" // mini bo prod
//const BASE_URL = "https://mini-bo-api-test.cleanbox.co.il" // mini bo test
//const BASE_URL = "https://localhost:5003" //local

//LOGIN
api.signIn = `${BASE_URL}/api/Login/LoginGeneral`
api.signInBody = (name, pwd) => {
  return {
    userName: name,
    password: pwd,
  }
}

//USERS
api.updateUserPassword = () => `${BASE_URL}/api/users/update_password`
api.getUsersByRole = `${BASE_URL}/api/users/get_users_by_role`
api.deleteUser = (userId) => `${BASE_URL}/api/users/delete_user/${userId}`
api.resetPassword = (userId) => `${BASE_URL}/api/users/reset_password/${userId}`
api.createUser = `${BASE_URL}/api/users/create_user`
api.createNewUserBody = ({ firstName, lastName, mobilePhone, stationId }) => {
  return {
    firstName,
    lastName,
    mobilePhone,
    stationId,
  }
}

//ORDERS
api.fetchOpenOrders = `${BASE_URL}/api/Orders/get_orders_general`
api.fetchL2LOpenOrders = `${BASE_URL}/api/Orders/get_orders_L2L_general`

api.getOrderTypes = `${BASE_URL}/api/orders/get_types`
api.getOrderDetails = (orderId) =>
  `${BASE_URL}/api/Orders/get_order_general/${orderId}`

//CREATE NEW  ORDER
//api.createOrder = `${BASE_URL}/api/orders/importOrdersWithStatus`
api.createOrder = `${BASE_URL}/api/orders/ImportOrdersByAccountType`
api.createOrderL2L = `${BASE_URL}/api/orders/ImportOrderL2L`

api.importOrdersFromCSVByAccount = (accountType, orderStatus) =>
  `${BASE_URL}/api/orders/importOrdersFromCSVByAccount/${accountType}/${orderStatus}`

//CREATE RETURN ORDER
api.createReturnOrder = `${BASE_URL}/api/orders/ImportReturns`

//UPDATE ORDER
api.updateOrder = `${BASE_URL}/api/orders/importOrdersWithStatus`

//ORDER STATUSES
api.getOrderStatuses = `${BASE_URL}/api/orders/get_status_types`

//UPDATE MESSAGE
api.updateMessage = (stationId) =>
  `${BASE_URL}/api/stations/update_station_message/${stationId}`

//GET STATIONS
api.getStations = `${BASE_URL}/api/stations/get_stations_by_account_name`

//GET LOCKER STATUSES
api.getLockersStatusesByStationId = (stationId) =>
  `${BASE_URL}/api/stations/lockers_status/${stationId}`

//GET LOCKERS LAYOUT
api.getLockersLayoutByStationId = (stationId) =>
  `${BASE_URL}/api/stations/get_layout/${stationId}`

//GET STATIONS
api.getStationsByRole = `${BASE_URL}/api/stations/get_stations_by_role`

//Reports
api.getPackageDebitReport = (month, year) =>
  `${BASE_URL}/api/DeliveryBilling/calcDeliveryBilling/${month}/${year}`

api.getDeliveryDetailsFromIsraelPost = (packageNumber) =>
  `${BASE_URL}/api/orders/get_israel_post_delivery/${packageNumber}`

api.getBarcodePDF = (packageNumber) =>
  `${BASE_URL}/api/Barcode/${packageNumber}`
api.getBarcodesPDFList = `${BASE_URL}/api/Barcode/GetMultipleBarcodes`

api.updateOrderStatus = (orderId, orderStatus) =>
  `${BASE_URL}/api/orders/updateOrderStatus/${orderId}/${orderStatus}`

api.updateOrderStatusAndExtentionDate = (
  orderId,
  orderStatus,
  inLockerExtentionDate
) =>
  `${BASE_URL}/api/orders/updateOrderStatusAndExtentionDate/${orderId}/${orderStatus}/${inLockerExtentionDate}`

api.updateOrderStatusByAccount = (
  orderId,
  orderStatus,
  inLockerExtentionDate
) =>
  `${BASE_URL}/api/orders/updateOrderStatusByAccount/${orderId}/${orderStatus}/${inLockerExtentionDate}`

// Locker to locker
api.importLockerToLockerOrders = `${BASE_URL}/api/orders/ImportLockerToLockerOrders`

api.getExternalNumByPackageAndAccount = (orderNumber, orderType) =>
  `${BASE_URL}/api/orders/GetExternalNumByPackageAndAccount/${orderNumber}/${orderType}`

api.getExternalStations = `${BASE_URL}/api/stations/get_external_stations`

export default api
