import React, { useState } from "react"
import "./style.scss"

const StationsTable = ({ stations, onItemStatusBtnClick }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" })

  const handleSort = (key) => {
    let direction = "asc"
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc"
    }
    setSortConfig({ key, direction })
  }

  const sortedStations = React.useMemo(() => {
    if (!sortConfig.key) return stations

    const sorted = [...stations].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "asc" ? -1 : 1
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "asc" ? 1 : -1
      return 0
    })
    return sorted
  }, [stations, sortConfig])

  return (
    <div className="stations-table-wrapper">
      <table className="stations-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("isAlive")}>
              מחובר
              {sortConfig.key === "isAlive" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("stationNumber")}>
              מזהה
              {sortConfig.key === "stationNumber" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("cityName")}>
              עיר
              {sortConfig.key === "cityName" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("branchName")}>
              שם סניף
              {sortConfig.key === "branchName" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("branchAddress")}>
              כתובת
              {sortConfig.key === "branchAddress" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("branchDisplayAddress")}>
              תיאור המקום
              {sortConfig.key === "branchDisplayAddress" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("isOutdoor")}>
              עמדה בחוץ
              {sortConfig.key === "isOutdoor" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("availabilityHours")}>
              שעות פעילות
              {sortConfig.key === "availabilityHours" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("stationPaymentVersion")}>
              עם מסך
              {sortConfig.key === "stationPaymentVersion" &&
                (sortConfig.direction === "asc" ? "↑" : "↓")}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedStations.length > 0 ? (
            sortedStations.map((station) => (
              <tr key={station.stationNumber}>
                <td>
                  {station.isAlive ? (
                    <i className="fa fa-check-circle  green"></i>
                  ) : (
                    <i className="fa fa-times-circle red"></i>
                  )}
                </td>
                <td>{station.stationNumber}</td>
                <td>{station.cityName}</td>
                <td>{station.branchName}</td>
                <td>{station.branchAddress}</td>
                <td>{station.branchDisplayAddress}</td>
                <td>
                  {station.isOutdoor ? (
                    <span className="green">כן</span>
                  ) : (
                    <span className="red">לא</span>
                  )}
                </td>
                <td>{station.availabilityHours}</td>
                <td>
                  {station.stationPaymentVersion == 4 ? (
                    <span className="red">לא</span>
                  ) : (
                    <span className="green">כן</span>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="no-data">
                אין רשומות
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default StationsTable
