import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import StationsFilterer from "../../../filters/StationsFilterer"
import StationsList from "./StationsList"
import StationsTable from "./StationTable"
import { Pagination } from "../../common/Pagination/"
import {
  getExternalStations,
  showStationLoading,
  clearLocalSearchStations,
  localSearchStationsSetPage,
  selectStation,
} from "../../../actions"
import { JsonToExcel } from "react-json-to-excel"
import "./style.scss"

const StationsScreen = ({ history }) => {
  const INIT_STATE = {
    pagedList: [],
  }

  const [pagedList, setPagedList] = useState(INIT_STATE.pagedList)

  const dispatch = useDispatch()

  const {
    isLoading,
    stationsFilteredListArr,
    pageNum,
    stations,
    authenticated,
  } = useSelector(({ station, users }) => {
    const { stations, stationsLocalSearch, isLoading } = station
    const { authenticated } = users
    const { stationsFilteredListArr, page } = stationsLocalSearch
    return {
      isLoading,
      stationsFilteredListArr,
      stations,
      pageNum: page,
      authenticated,
    }
  })

  useEffect(() => {
    if (!authenticated) {
      history.push("/")
      return
    }

    dispatch(getExternalStations())
    dispatch(showStationLoading())

    return () => {
      dispatch(clearLocalSearchStations())
    }
  }, [authenticated, dispatch, history])

  const resetCurrentPage = () => {
    dispatch(localSearchStationsSetPage(1))
    setPagedList([])
  }

  const onItemStatusBtnClick = (item) => {
    dispatch(selectStation(item))
    history.push("/StationStatusScreen")
  }

  const getColumnsForExportFull = (stations) => {
    if (stations.length === 0) return []

    // Hebrew headers mapping
    const headers = {
      isAlive: "פעיל",
      stationNumber: "מזהה",
      cityName: "העיר",
      branchName: "שם הסניף",
      branchAddress: "כתובת",
      branchDisplayAddress: "תיאור המקום",
      isOutdoor: "עמדה בחוץ",
      availabilityHours: "שעות פעילות",
      stationPaymentVersion: "עם מסך",
    }

    // Transform stations to include Hebrew headers
    return stations.map((station) => ({
      [headers.stationNumber]: station.stationNumber,
      [headers.isAlive]: station.isAlive ? "כן" : "לא",
      [headers.cityName]: station.cityName,
      [headers.branchName]: station.branchName,
      [headers.branchAddress]: station.branchAddress,
      [headers.branchDisplayAddress]: station.branchDisplayAddress,
      [headers.isOutdoor]: station.isOutdoor ? "כן" : "לא",
      [headers.availabilityHours]: station.availabilityHours,
      [headers.stationPaymentVersion]:
        station.stationPaymentVersion === 4 ? "לא" : "כן",
    }))
  }
  const createFileName = () => {
    const date = new Date().toLocaleDateString()
    const time = new Date().toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
    })
    return `station_${date}_${time}`
  }

  return (
    <div className="stations-page-wrapper">
      <h1>רשימת עמדות</h1>
      <div className="stations-header">
        <StationsFilterer
          key={stations}
          resetCurrentPage={resetCurrentPage}
          stations={stations}
        />
        <div className="export">
          <JsonToExcel
            title="ייצוא לאקסל"
            data={getColumnsForExportFull(stations)}
            fileName={createFileName()}
            btnClassName="stations-export"
          />
        </div>
      </div>
      <div key={pagedList.length}>
        <StationsTable
          stations={pagedList}
          onItemStatusBtnClick={onItemStatusBtnClick}
        />
        <Pagination
          fullList={stationsFilteredListArr}
          totalPerPage={10}
          onSubListReady={(pagedList, pageNum) => {
            dispatch(localSearchStationsSetPage(pageNum))
            setPagedList(pagedList)
          }}
          pageNum={pageNum}
        />
      </div>
    </div>
  )
}

export default StationsScreen
