import React, { Component } from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Store from "./store"

import AppLayout from "./components/AppLayout"
import LoginScreen from "./components/screens/LoginScreen"
import OrdersListScreen from "./components/screens/OrdersListScreen"
import CreateNewOrderScreen from "./components/screens/CreateNewOrderScreen"
import CreateLockerToLockerScreen from "./components/screens/CreateLockerToLockerScreen"
import UpdateOrderScreen from "./components/screens/UpdateOrderScreen"
import UpdateMessageScreen from "./components/screens/UpdateMessageScreen"
import StationStatusScreen from "./components/screens/StationStatusScreen"
import StationsScreen from "./components/screens/StationsScreen"
import ChangePasswordScreen from "./components/screens/ChangePasswordScreen"
import UsersListScreen from "./components/screens/UsersListScreen"
import NewUser from "./components/screens/UsersListScreen/NewUser"
import PackageDebitReport from "./components/screens/PackageDebitReport"
import CreateReturnOrderScreen from "./components/screens/CreateReturnOrderScreen"
import ScanOrderScreen from "./components/screens/ScanOrderScreen"

import "./App.scss"

class App extends Component {
  constructor(props) {
    super(props)

    //this.autoLogouterTrigger = null;
  }

  componentWillMount() {
    //initAxiosLogger();
  }

  componentDidMount() {
    //document.addEventListener('click', () => {
    // if (isProdEnv()) {
    //     new Audio(beep).play();         //! UNCOMMENT TO GET SOUND!
    // }
    //this.autoLogouterTrigger(new Date().getMilliseconds());
    //});
    //console.log(Environment, AppVersion);
  }

  triggerCallbackSetter(triggerFunc, cntx) {
    //console.log('App - triggerCallbackSetter :'+cntx);
    //this.autoLogouterTrigger = (clickTime) => triggerFunc(cntx, clickTime);
  }

  render() {
    return (
      <Provider store={Store}>
        <Router>
          <AppLayout>
            <Switch>
              <Route
                exact
                path="/OrdersListScreen"
                component={OrdersListScreen}
              />
              <Route
                exact
                path="/CreateNewOrderScreen"
                component={CreateNewOrderScreen}
              />
              <Route
                exact
                path="/CreateReturnOrderScreen"
                component={CreateReturnOrderScreen}
              />

              <Route
                exact
                path="/CreateLockerToLockerScreen"
                component={CreateLockerToLockerScreen}
              />
              <Route
                exact
                path="/UpdateOrderScreen"
                component={UpdateOrderScreen}
              />
              <Route
                exact
                path="/UpdateMessageScreen"
                component={UpdateMessageScreen}
              />
              <Route
                exact
                path="/StationStatusScreen"
                component={StationStatusScreen}
              />
              <Route
                exact
                path="/ChangePasswordScreen"
                component={ChangePasswordScreen}
              />
              <Route
                exact
                path="/UsersListScreen"
                component={UsersListScreen}
              />
              <Route
                exact
                path="/PackageDebitReport"
                component={PackageDebitReport}
              />
              <Route
                exact
                path="/ScanOrderScreen"
                component={ScanOrderScreen}
              />
              <Route exact path="/NewUser" component={NewUser} />
              <Route exact path="/StationsScreen" component={StationsScreen} />
              <Route exact path="/" component={LoginScreen} />
            </Switch>
          </AppLayout>
        </Router>
      </Provider>
    )
  }
}
//
export default App
